import logo from "./logo.svg";
import "./App.css";
import "./static/images/bg5.png";
import { useEffect, useState, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BX from "wow-bx24";
import validate from "validate.js";
import queryString from "query-string";
import emailjs from "@emailjs/browser";

import Icon1 from "./static/images/icon1.png";
import Icon2 from "./static/images/icon2.png";
import Icon3 from "./static/images/icon3.png";
import Icon4 from "./static/images/icon4.png";
import Icon5 from "./static/images/icon5.png";
import Icon6 from "./static/images/icon6.png";
import Icon7 from "./static/images/icon7.png";
import Icon8 from "./static/images/icon8.png";
import Icon12 from "./static/images/icon12.png";
import Icon13 from "./static/images/icon13.png";
import Icon14 from "./static/images/icon14.png";
import Icon15 from "./static/images/icon15.png";

import Img1 from "./static/images/image1.png";
import Img2 from "./static/images/image2.png";
import Img3 from "./static/images/image3.png";
import Img4 from "./static/images/image4.png";
import Img5 from "./static/images/image5.png";
import Img6 from "./static/images/image6.png";
import Img7 from "./static/images/image7.png";
import Img8 from "./static/images/image8.png";
import Img9 from "./static/images/image9.png";
import Img10 from "./static/images/4.png";
import Img11 from "./static/images/1.png";
import Img12 from "./static/images/2.png";
import Img13 from "./static/images/3.png";
import Img14 from "./static/images/image14.png";
import Img15 from "./static/images/image15.png";
import Img16 from "./static/images/image16.png";
import Img17 from "./static/images/image17.png";
import Img18 from "./static/images/image18.png";
import Img19 from "./static/images/image19.png";
import Img20 from "./static/images/image20.png";
import Img21 from "./static/images/image21.png";
import Img22 from "./static/images/image22.png";
import Img23 from "./static/images/image23.png";
import Img24 from "./static/images/image24.png";
import Img25 from "./static/images/image25.png";
import Img26 from "./static/images/image26.png";
import Img27 from "./static/images/image27.png";
import Img28 from "./static/images/image28.png";
import Img30 from "./static/images/5.png";
import Img31 from "./static/images/6.png";
import Img32 from "./static/images/7.png";
import Img33 from "./static/images/8.png";
import Img34 from "./static/images/9.png";
import Img35 from "./static/images/10.png";
import Img36 from "./static/images/11.png";
import Img37 from "./static/images/12.png";
import Img38 from "./static/images/13.png";
import Img39 from "./static/images/14.png";
import Img40 from "./static/images/15.png";

import Logo from "./static/images/logo3.png";
import Logo2 from "./static/images/logo2.png";
import Bg1 from "./static/images/bg1.png";
import Bg12 from "./static/images/bg12.png";
import Bg3 from "./static/images/bg3.png";
import Bg4 from "./static/images/bg4.png";
import Bg6 from "./static/images/bg6.png";
import Slider from "react-slick";

const $bx = new BX(
  "https://actioncoachcbd.bitrix24.com/rest/1186/i13eqbrjr4cs96oa/batch"
);
const timeNow = new Date();
const constraints = {
  name: {
    presence: { message: "Vui lòng nhập họ và tên." },
    length: {
      minimum: 3,
      tooShort: "Họ và tên không được ngắn hơn %{count} ký tự.",
      maximum: 50,
      tooLong: "Họ và tên không được dài hơn %{count} ký tự.",
    },
  },
  phone: {
    presence: { message: "Vui lòng nhập số điện thoại." },
    length: {
      minimum: 8,
      tooShort: "Số điện thoại không được ngắn hơn %{count} ký tự.",
      maximum: 12,
      tooLong: "Số điện thoại không được dài hơn %{count} ký tự.",
    },
  },
  email: {
    presence: { message: "Vui lòng nhập email." },
    email: {
      message: "Định dạng email không đúng.",
    },
  },
  // company: {
  //   presence: { message: "Vui lòng nhập tên công ty." },
  //   length: {
  //     minimum: 3,
  //     tooShort: "Tên công ty không được ngắn hơn %{count} ký tự.",
  //     maximum: 150,
  //     tooLong: "Tên công ty không được dài hơn %{count} ký tự.",
  //   },
  // },
  // position: {
  //   presence: { message: "Vui lòng nhập chức danh." },
  //   length: {
  //     minimum: 3,
  //     tooShort: "Vui lòng nhập chức danh.",
  //     maximum: 150,
  //   },
  // },
  quality: {
    presence: { message: "Vui lòng nhập số lượng vé." },
    numericality: {
      notValid: "Vui lòng nhập số lượng vé.",
      greaterThan: 0,
      notGreaterThan: "Nhập tối thiểu một vé.",
    },
  },
};

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <img className=" hidden md:block" src={Icon12} />
    </button>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button className={className} onClick={onClick}>
      <img className=" hidden md:block" src={Icon13} />
    </button>
  );
}
const numberFormat = (num) => {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false,
  });
};
const timediff = Math.floor(
  new Date("August 26,2022 00:00:00").getTime() - new Date().getTime()
);
function App() {
  const [load, setLoad] = useState(false);
  const [error, setError] = useState(false);
  const parsed = queryString.parse(window.location.search);
  const [state, setState] = useState({
    title: "CRM_FORM_VNDN 27/08/2022",
    name: parsed.full_name ? parsed.full_name : "",
    phone: parsed.phone ? parsed.phone : "",
    email: parsed.email ? parsed.email : "",
    company: parsed.company_name ? parsed.company_name : "",
    position: parsed.position ? parsed.position : "",
    source: parsed.rid && parsed.rid != 966 ? 3 : "WEBFORM",
    quality: "",
    product: 16108,
    rid: parsed.rid || 966,
  });
  const [deadline, setDeadline] = useState({
    days: "00",
    hours: "00",
    minutes: "00",
    seconds: "00",
  });

  const form = useRef();

  const sendEmail = (e) => {
    const valid = validate(state, constraints);
    if (valid) {
      setError(valid);
      return;
    }
    emailjs
      .sendForm(
        "service_vowy7ij",
        "template_j192eoa",
        form.current,
        "F2bxKCoQoMIT3Wx8d"
      )
      .then(
        (result) => {
          console.log(result.text);
          setLoad(true);
        },
        (error) => {
          console.log(error.text);
          setLoad(false);
        }
      );
  };

  const [duration, setDuration] = useState(timediff);
  useEffect(() => {
    if (timediff > 0) {
      const timer = setTimeout(() => {
        setDuration(duration - 1000);
        setDeadline({
          days: numberFormat(Math.floor(duration / (1000 * 60 * 60 * 24))),
          hours: numberFormat(
            Math.floor((duration % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
          ),
          minutes: numberFormat(
            Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60))
          ),
          seconds: numberFormat(Math.floor((duration % (1000 * 60)) / 1000)),
        });
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [duration]);

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => {
    setOpenMenu(!openMenu);
  };

  const [openModal, setOpenModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const handleModal = () => {
    setOpenModal(!openModal);
    setSuccess(openModal);
  };

  const settings = {
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const settings1 = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
  };
  const handleSubmit = (event) => {
    setLoad(true);
    event.preventDefault();
    setError(false);
    const valid = validate(state, constraints);

    if (valid) {
      setError(valid);
      return;
    }
    $bx
      .call("crm.lead.add", {
        FIELDS: {
          TITLE: state.title,
          NAME: state.name,
          PHONE: [{ VALUE: state.phone, VALUE_TYPE: "WORK" }],
          EMAIL: [{ VALUE: state.email, VALUE_TYPE: "WORK" }],
          COMPANY_TITLE: state.company,
          ASSIGNED_BY_ID: state.rid,
          SOURCE_ID: state.source,
          SOURCE_DESCRIPTION: "",
          POSITION: state.position,
        },
      })
      .then((result) => {
        const leadId = result.result;
        // $bx.call("crm.product.get", { id: state.product }).then((result2) => {
        //   let price = result2.result.PRICE;
        $bx
          .call("crm.lead.productrows.set", {
            id: leadId,
            rows: [
              {
                PRODUCT_ID: state.product,
                PRICE:
                  state.quality > 9
                    ? 399000
                    : state.quality > 4
                    ? 499000
                    : 999000,
                TAX_RATE: 8,
                TAX_INCLUDED: "YES",
                QUANTITY: state.quality,
              },
            ],
          })
          .then((result3) => {
            setState({
              ...state,
              name: "",
              phone: "",
              email: "",
              company: "",
              position: "",
              quality: "",
            });
            sendEmail();
            setSuccess(true);
            setLoad(false);
          })
          .catch((error) => setError(true));
        setLoad(false);
        // });
      })
      .catch((error) => setError(true));
  };
  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };
  const Form = !success ? (
    <form
      ref={form}
      onSubmit={handleSubmit}
      className="bg-white shadow-md rounded px-2 md:px-8 pt-6 pb-8 mb-4 mx-auto w-[95%] max-w-[500px] static z-[99]"
    >
      <div className="text-right relative">
        <span
          className="text-[30px] absolute font-[900] top-[-18px] right-[0px] md:right-[-25px]"
          onClick={handleModal}
        >
          <img className="w-[30px]" src={Icon15} />
        </span>
      </div>
      <div className="m-4 mt-6">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          type="text"
          id="name"
          name="name"
          value={state.name}
          placeholder="Họ và tên"
          onChange={handleChange}
        />
        <p className="text-red-600">{error.name ? error.name[0] : ""}</p>
      </div>
      <div className="m-4">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          id="email"
          name="email"
          placeholder="email@gmail.com"
          type="email"
          value={state.email}
          onChange={handleChange}
        />
        <p className="text-red-600">{error.email ? error.email[0] : ""}</p>
      </div>
      <div className="m-4">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          id="phone"
          placeholder="Số điện thoại (VD: 0900xxxxxx)"
          type="phone"
          value={state.phone}
          onChange={handleChange}
        />
        <p className="text-red-600">{error.phone ? error.phone[0] : ""}</p>
      </div>
      <div className="m-4">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          id="position"
          placeholder="Chức vụ"
          type="text"
          value={state.position}
          onChange={handleChange}
        />
        {/* <p className="text-red-600">
          {error.position ? error.position[0] : ""}
        </p> */}
      </div>
      <div className="m-4">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          id="company"
          placeholder="Tên doanh nghiệp"
          type="text"
          value={state.company}
          onChange={handleChange}
        />
        {/* <p className="text-red-600">{error.company ? error.company[0] : ""}</p> */}
      </div>
      <div className="m-4">
        <input
          className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal"
          id="quality"
          type="number"
          placeholder="Số lượng vé"
          value={state.quality}
          onChange={handleChange}
        />
        <p className="text-red-600">{error.quality ? error.quality[0] : ""}</p>
      </div>
      <div className="m-4 text-center">
        {load == false ? (
          <button
            type="submit"
            className=" uppercase bg-r mt-2 rounded-full py-2 px-10 hover:shadow-2xl text-white font-[700] w-full"
          >
            Đăng ký ngay
          </button>
        ) : (
          <button
            disabled
            className=" uppercase bg-r mt-2 rounded-full py-2 px-10 hover:shadow-2xl text-white font-[700] w-full flex justify-center"
          >
            <svg
              className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                className="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                strokeWidth="4"
              ></circle>
              <path
                className="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
            Đang gửi
          </button>
        )}
        {error === true ? <p>Đã có lỗi xảy ra xin vui lòng thử lại.</p> : ""}
      </div>
    </form>
  ) : (
    <div className="bg-white shadow-md rounded px-2 md:px-8 pt-6 pb-8 mb-4 mx-auto w-[95%] max-w-[500px] static z-[99]">
      <div className="text-right relative">
        <span
          className="text-[30px] absolute font-[900] top-[-18px] right-[0px] md:right-[-25px]"
          onClick={handleModal}
        >
          <img className="w-[30px]" src={Icon15} />
        </span>
      </div>
      <h3 className="text-green-600 text-center uppercase text-[32px] font-bold">
        Đăng ký thành công
      </h3>
      <p className="mt-4">
        Cảm ơn Anh/Chị đã đăng ký thành công. ActionCOACH CBD Firm sẽ liên hệ tư
        vấn trong thời gian sớm nhất.
      </p>
    </div>
  );
  // const [number, setNumber] = useState(0);
  // if(number < 5){
  //   total_price = total_price + (number * 999000);
  // }else if(number >= 5 && number < 10){
  //   total_price = total_price + number * 499000;
  // }else if(number >= 10){
  //   total_price = total_price + number * 399000;
  // }else{
  //   total_price = total_price + 0;
  // }

  return (
    <div>
      <div className="w-full bg-b">
        <div className="ed-container">
          <nav className="bg-b px-2 sm:px-4 py-2.5  w-full z-20 top-0 left-0 ">
            <div className="container flex flex-wrap justify-between items-center mx-auto">
              <div className="w-[190px] h-[56px]">
                <img
                  src={Logo}
                  className="mr-3 w-[190px]"
                  alt="ActionCOACH CBD Firm"
                />
              </div>
              <div>
                <button
                  onClick={handleMenu}
                  data-collapse-toggle="navbar-sticky"
                  type="button"
                  className="inline-flex items-center p-2 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
                  aria-controls="navbar-sticky"
                  aria-expanded="false"
                >
                  <span className="sr-only">Open main menu</span>
                  <svg
                    className="w-6 h-6"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                </button>
              </div>
              {openMenu ? (
                <div
                  className=" cl-12127 justify-between items-center w-full md:flex md:w-auto"
                  id="navbar-sticky"
                >
                  <ul className=" flex flex-col p-4 mt-4 bg-b rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    <li>
                      <a
                        href="#noi-dung"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        NỘI DUNG
                      </a>
                    </li>
                    <li>
                      <a
                        href="#gia-tri"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        GIÁ TRỊ{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#dien-gia"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        DIỄN GIẢ{" "}
                      </a>
                    </li>
                    <li>
                      <button className=" block md:hidden uppercase bg-white mt-2 rounded-full py-2 px-10 hover:shadow-2xl text-[#D22741] font-[700] cl-shake">
                        Đăng ký ngay
                      </button>
                    </li>
                  </ul>
                </div>
              ) : (
                <div
                  className=" cl-12127 hidden justify-between items-center w-full md:flex md:w-auto"
                  id="navbar-sticky"
                >
                  <ul className="flex flex-col p-4 mt-4 bg-b rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-white dark:bg-gray-800 md:dark:bg-gray-900 dark:border-gray-700">
                    <li>
                      <a
                        href="#noi-dung"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        NỘI DUNG
                      </a>
                    </li>
                    <li>
                      <a
                        href="#gia-tri"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        GIÁ TRỊ{" "}
                      </a>
                    </li>
                    <li>
                      <a
                        href="#dien-gia"
                        className="block py-2 pr-4 pl-3 text-white rounded hover:bg-gray-100 md:hover:bg-transparent md:hover:text-blue-700 md:p-0 md:dark:hover:text-white dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                      >
                        DIỄN GIẢ{" "}
                      </a>
                    </li>
                    <li>
                      <button
                        onClick={handleModal}
                        className=" block md:hidden uppercase bg-white rounded-full py-1 px-10 hover:shadow-2xl text-[#D22741] font-[700]"
                      >
                        Đăng ký ngay
                      </button>
                    </li>
                  </ul>
                </div>
              )}

              <div className="hidden md:block ">
                <button
                  onClick={handleModal}
                  className="uppercase bg-white rounded-full py-3 px-10 hover:shadow-2xl text-[#D22741] font-[700] cl-shake"
                >
                  Đăng ký ngay
                </button>
              </div>
            </div>
          </nav>
        </div>
      </div>

      <div className="w-full">
        <div className="grid lg:grid-cols-2">
          <div className="w-full">
            <img src={Img24} className="cl-12123" />
          </div>
          <div className="cl-box1 pt-[20px] pb-[20px] lg:px-0 px-5 md:pt-[60px] md:mt-4">
            <h4 className="max-w-[370px] w-[100%] text-center text-[18px] md:text-[24px] bg-r">
              <span className="text-white">CHƯƠNG TRÌNH HUẤN LUYỆN</span>
            </h4>
            <h2 className="uppercase font-extrabold mt-4">
              <span className="text-[20px] md:text-[40px] ">Xây dựng</span>{" "}
              <span className="cl-r text-[32px] md:text-[60px]">Văn Hoá </span>{" "}
            </h2>
            <h3 className="text-[32px] leading-8 font-extrabold md:text-[60px]">
              DOANH NGHIỆP
            </h3>
            <div className="grid grid-cols-12 cl-12122 gap-4 pt-6">
              <div className="md:col-span-5 col-span-12">
                <h3>Dành cho </h3>
                <div className="flex">
                  <img src={Icon1} />
                  <span>Chủ doanh nghiệp/CEO</span>
                </div>

                <div className="flex">
                  <img src={Icon1} />
                  <span>Thành viên Hội đồng Quản trị</span>
                </div>

                <div className="flex">
                  <img src={Icon1} />
                  <span>Phòng Nhân sự & Nhân sự chủ chốt</span>
                </div>
              </div>

              <div className="md:col-span-4 col-span-12">
                <h3>Thời gian</h3>
                <div className="flex">
                  <img src={Icon1} />
                  <span>
                    08h00 - 17h00
                    <br className="hidden md:block" /> Thứ Bảy,{" "}
                    <br className="hidden md:block" /> Ngày 27/08/2022
                  </span>
                </div>
              </div>

              <div className="md:col-span-3 col-span-12">
                <h3>Hình thức </h3>
                <div className="flex">
                  <img src={Icon1} />
                  <span>
                    ONLINE & <br className="hidden md:block" />
                    Thảo luận nhóm
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-img1 py-[20px] md:py-[60px]">
        <div className="ed-container">
          <div className="lg:grid grid-cols-12">
            <div className="col-span-5 cl-0281">
              <iframe
                width="100%"
                loading="lazy"
                height="370px"
                src={`https://www.youtube.com/embed/uLRudEFbdng`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Từ GIÁ TRỊ BẢN THÂN đến VĂN HÓA DOANH NGHIỆP"
              />
            </div>

            <div className="col-span-7 lg:ml-[30px]  lg:px-0 px-5 mt-[30px] md:mt-0">
              <div className="block md:flex m-auto">
                <div>
                  <img src={Icon2} className="mr-8" />
                </div>
                <div>
                  <h3 className="text-[30px] font-[500] cl-b uppercase">
                    Văn hóa doanh nghiệp xơi tái{" "}
                    <br className="hidden md:block" /> chiến lược trong một bữa
                    sáng
                  </h3>
                  <p className="italic">– Peter Drucker</p>
                </div>
              </div>
              <div>
                <p className="mb-5 lg:mt-2 mt-5 text-justify">
                  "Văn hóa doanh nghiệp xơi tái chiến lược trong một bữa sáng" –
                  Đây chính là nhận định kinh điển của Peter Drucker - chuyên
                  gia tư vấn quản trị và được mệnh danh là "nhà sinh thái học xã
                  hội" về Văn hóa Doanh nghiệp.
                </p>
                <p className="text-justify">
                  Trên thực tế, chúng ta đang sống trong thế giới VUCA - một thế
                  giới mà ta không thể dự đoán trước được bất cứ điều gì. Giống
                  như Đại dịch sau 3 năm đã làm thay đổi mọi thứ. Vậy điều gì
                  giúp cho Doanh nghiệp vượt qua mọi khủng hoảng, hiện tại cũng
                  như tương lai? Đó chỉ có thể là Văn hóa doanh nghiệp.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full text-center mt-10 ">
            <button
              onClick={handleModal}
              className="uppercase bg-[#D22741] rounded-full py-3 px-10 hover:shadow-2xl text-white cl-shake"
            >
              Đăng ký ngay hôm nay
            </button>
          </div>
          <div className="bg-img2 my-4 md:my-10 ">
            <div className="py-6 text-center lg:px-5 px-5">
              <p>
                Văn hóa Doanh nghiệp là thứ duy nhất còn thiếu khi ta có tất cả
                và là điều còn tồn tại duy nhất khi tất cả mất đi.{" "}
              </p>
              <p>
                Chỉ khi duy trì được VĂN HÓA DOANH NGHIỆP BỀN VỮNG, doanh nghiệp
                mới được hưởng những lợi ích dài lâu về nhiều khía cạnh.
              </p>
            </div>
          </div>

          <div>
            <h3 className="text-[20px] font-medium cl-b text-center mb-4">
              THẾ NHƯNG, DOANH NGHIỆP CỦA ANH/CHỊ ĐÃ CÓ VĂN HÓA DOANH NGHIỆP{" "}
              <br className=" hidden md:block" />
              SONG LẠI GẶP NHỮNG VẤN ĐỀ SAU
            </h3>
            <div className="grid lg:grid-cols-3 grid-cols-1 text-center gap-10 max-w-[1180px] m-auto">
              <div>
                <img className="m-auto" src={Img2} />
              </div>
              <div>
                <img className="m-auto" src={Img4} />
              </div>
              <div>
                <img className="m-auto" src={Img3} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="gia-tri" className="w-full mt-[40px] lg:px-0 px-5">
        <h2 className="text-[32px] md:text-[40px] text-center cl-b font-[500] mb-3">
          GIÁ TRỊ & LỢI ÍCH KHI THAM GIA CHƯƠNG TRÌNH
        </h2>
        <div className="grid xl:grid-cols-5 lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
          <div className="m-auto">
            <img src={Img5} loading="lazy" />
            <p className="mt-4 max-w-[350px]">
              Gọi tên được <strong></strong> Giá trị Văn hóa trong doanh nghiệp
              của mình.{" "}
            </p>
          </div>
          <div className="m-auto">
            <img src={Img6} loading="lazy" />
            <p className="mt-4  max-w-[350px]">
              Đưa Giá trị Văn hóa vào{" "}
              <strong>Mô hình kinh doanh, Mô hình vận hành</strong> doanh
              nghiệp.
            </p>
          </div>
          <div className="m-auto">
            <img src={Img7} loading="lazy" />
            <p className="mt-4 max-w-[350px]">
              Dựa vào Giá trị Văn hóa để <strong>Tuyển dụng Đội ngũ</strong> phù
              hợp với doanh nghiệp của mình.{" "}
            </p>
          </div>
          <div className="m-auto">
            <img src={Img8} loading="lazy" />
            <p className="mt-4 max-w-[350px]">
              Đưa Giá trị Văn hóa trở thành những{" "}
              <strong>tiêu chuẩn để đào tạo Đội ngũ</strong> cũng như lựa chọn
              Đội ngũ.{" "}
            </p>
          </div>
          <div className="m-auto">
            <img src={Img9} loading="lazy" />
            <p className="mt-4  max-w-[350px]">
              Đưa Giá trị Văn hóa trở thành{" "}
              <strong>Một chìa khóa để chuyển giao doanh nghiệp</strong> cho thế
              hệ kế thừa.{" "}
            </p>
          </div>
        </div>
      </div>
      <div id="dien-gia" className="w-full py-[60px] lg:px-0 px-5">
        <h2 className="text-[32px] md:text-[40px] text-center cl-b font-[500] mb-3">
          DIỄN GIẢ TẠI <br className="block md:hidden" />
          SỰ KIỆN
        </h2>
        <div className="ed-container cl-0211">
          {/* <div className='grid grid-rows-1 lg:grid-cols-4 md:grid-cols-2 grid-cols-1 gap-4'> */}
          <Slider {...settings}>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1i__Cs-i3fcEDdQhUFSqEJjIlfoyb6ccM/view?usp=sharing"
              >
                <img className="m-auto" src={Img10} />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1seKISiZGO0VIH_9GqPD9dHym_RzGyGZH/view"
              >
                <img className="m-auto" src={Img13} />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1hwhMF9Da0Q5bsjr-_JQQBdWGuRUmY2pE/view"
              >
                <img className="m-auto" src={Img11} />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1L_XJgAE-2VqW8HGOvwGbdqAHYri0PMVQ/view?usp=sharing"
              >
                <img className="m-auto" src={Img12} />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1yTaJTPEWtXqKZC61XCfWNCKt7XWo_JWa/view?usp=sharing"
              >
                <img className="m-auto" src={Img30} />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/10Qtd7MHwmAoMfqAi5hCnKxxa_tiVkWB2/view"
              >
                <img className="m-auto" src={Img31} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1l5tAhLe2Smc50vheYlDSFlZN05HVmvlM/view"
              >
                <img className="m-auto" src={Img32} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1MA-1N0HysnG1XARqT2haKg3uLkzPI2oB/view"
              >
                <img className="m-auto" src={Img33} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1Br-JoQxY9Un3-WCSiUN9-AttQGT37kI1/view?usp=sharing"
              >
                <img className="m-auto" src={Img34} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1IKgBmDEFo4iYfUIwqcmn6KC0lGci_Kbr/view?usp=sharing"
              >
                <img className="m-auto" src={Img35} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1yk9FwX3O5t9MXxvII0nljYFQFNYAGEim/view?usp=sharing"
              >
                <img className="m-auto" src={Img36} loading="lazy" />
              </a>
            </div>
            {/* <div>
              <a target="_blank" className="xem-them" href="">
                <img className="m-auto" src={Img37} loading="lazy" />
              </a>
            </div> */}
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1YQs0g4CrgF07ykliHbYJDhbU-d1SVH4-/view"
              >
                <img className="m-auto" src={Img38} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1diyqtdgmeUCd8f_wcXUEVmE5Pf6MGCxg/view"
              >
                <img className="m-auto" src={Img39} loading="lazy" />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                className="xem-them"
                href="https://drive.google.com/file/d/1qdy6OllNjjJgjXTRTcga0NAPsmH7iD3C/view"
              >
                <img className="m-auto" src={Img40} loading="lazy" />
              </a>
            </div>
          </Slider>
          {/* </div> */}
        </div>
      </div>

      <div id="noi-dung" className="w-full bg-[#F3F3F3] py-[60px] lg:px-0 px-5">
        <h2 className="text-[40px] text-center cl-b font-[500] mb-3">
          NỘI DUNG HUẤN LUYỆN
        </h2>
        <div className="ed-container">
          <div className="grid grid-rows-1 md:grid-cols-6 gap-2 md:gap-8">
            <div className="md:col-span-1 col-span-6 bg-r p-5 flex items-center uppercase font-semibold">
              <div className="text-center w-full text-white">
                <span className="text-[32px]">Phần</span>
                <br className="hidden md:block" />
                <span className="mb:text-[48px]  text-[32px]"> 1</span>
              </div>
            </div>
            <div className="md:col-span-5 col-span-6 grid grid-cols-3 gap-4 p-4 md:p-7 bg-white">
              <div className="md:col-span-2 col-span-3">
                <a
                  target="_blank"
                  className="xem-them"
                  href="https://drive.google.com/file/d/1i__Cs-i3fcEDdQhUFSqEJjIlfoyb6ccM/view?usp=sharing"
                >
                  <div className="font-black text-[24px]">
                    Từ Giá trị bản thân đến Văn hóa Doanh nghiệp
                  </div>
                  <div className="flex gap-5 mt-6">
                    <img className="w-[60px] h-[60px]" src={Icon3} />
                    <div>
                      <p>
                        <span>Bà</span>
                      </p>
                      <p>
                        <span className="text-[20px] font-bold  cl-b">
                          Anna Hằng Nguyễn
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="hidden md:block">
                <img src={Img22} loading="lazy" />
              </div>
            </div>
          </div>

          <div className="grid grid-rows-1 md:grid-cols-6  gap-2 md:gap-8 mt-8">
            <div className="md:col-span-1 col-span-6 bg-r p-5 flex items-center uppercase font-semibold">
              <div className="text-center w-full text-white">
                <span className="text-[32px]">Phần</span>
                <br className="hidden md:block" />
                <span className="mb:text-[48px]  text-[32px]"> 2</span>
              </div>
            </div>
            <div className="md:col-span-5 col-span-6 grid grid-cols-3 gap-4 p-4 md:p-7 bg-white">
              <div className="md:col-span-2 col-span-3">
                <a
                  target="_blank"
                  className="xem-them"
                  href="https://drive.google.com/file/d/1seKISiZGO0VIH_9GqPD9dHym_RzGyGZH/view"
                >
                  <div className="font-black text-[24px]">
                    Văn hóa Doanh nghiệp quyết định Phương pháp Xây dựng <br />
                    hệ thống và Vận hành Doanh nghiệp
                  </div>

                  <div className="flex gap-5 mt-6">
                    <img className="w-[60px] h-[60px]" src={Icon7} />
                    <div>
                      <p>
                        <span className="uppercase">Coach</span>
                      </p>
                      <p>
                        <span className="text-[20px] font-bold cl-b ">
                          TONY Thái Sơn
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="hidden md:block">
                <img src={Img21} loading="lazy" />
              </div>
            </div>
          </div>

          <div className="grid grid-rows-1 md:grid-cols-6  gap-2 md:gap-8 mt-8">
            <div className="md:col-span-1 col-span-6 bg-r p-5 flex items-center uppercase font-semibold">
              <div className="text-center w-full text-white">
                <span className="text-[32px]">Phần</span>
                <br className="hidden md:block" />
                <span className="mb:text-[48px]  text-[32px]"> 3</span>
              </div>
            </div>
            <div className="md:col-span-5 col-span-6 grid grid-cols-3 gap-4 p-4 md:p-7 bg-white">
              <div className="md:col-span-2 col-span-3">
                <a
                  target="_blank"
                  className="xem-them"
                  href="https://drive.google.com/file/d/1hwhMF9Da0Q5bsjr-_JQQBdWGuRUmY2pE/view"
                >
                  <div className="font-black text-[24px]">
                    Văn hóa Doanh nghiệp quyết định Chất lượng Nguồn nhân lực
                  </div>
                  <div className="flex gap-5 mt-6">
                    <img className="w-[60px] h-[60px]" src={Icon5} />
                    <div>
                      <p>
                        <span className="uppercase">Coach</span>
                      </p>
                      <p>
                        <span className="text-[20px]  font-bold cl-b ">
                          KAKA Lê Ngọc Đăng
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="hidden md:block">
                <img src={Img20} loading="lazy" />
              </div>
            </div>
          </div>

          <div className="grid grid-rows-1 md:grid-cols-6  gap-2 md:gap-8 mt-8">
            <div className="md:col-span-1 col-span-6 bg-r p-5 flex items-center uppercase font-semibold">
              <div className="text-center w-full text-white">
                <span className="text-[32px]">Phần</span>
                <br className="hidden md:block" />
                <span className="mb:text-[48px]  text-[32px]"> 4</span>
              </div>
            </div>
            <div className="md:col-span-5 col-span-6 grid grid-cols-3 gap-4 p-4 md:p-7 bg-white">
              <div className="md:col-span-2 col-span-3">
                <a
                  target="_blank"
                  className="xem-them"
                  href="https://drive.google.com/file/d/1L_XJgAE-2VqW8HGOvwGbdqAHYri0PMVQ/view?usp=sharing"
                >
                  <div className="font-black text-[24px]">
                    Văn hóa Doanh nghiệp quyết định Chất lượng đội ngũ Kế thừa
                    và Kế nghiệp
                  </div>

                  <div className="flex gap-5 mt-6">
                    <img className="w-[60px] h-[60px]" src={Icon6} />
                    <div>
                      <p>
                        <span className="uppercase">Coach</span>
                      </p>
                      <p>
                        <span className="text-[20px] font-bold cl-b">
                          LEO Võ Thái Lâm
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="hidden md:block">
                <img src={Img19} loading="lazy" />
              </div>
            </div>
          </div>

          <div className="grid grid-rows-1 md:grid-cols-6  gap-2 md:gap-8 mt-8">
            <div className="md:col-span-1 col-span-6 bg-r p-5 flex items-center uppercase font-semibold">
              <div className="text-center w-full text-white">
                <span className="text-[32px]">Phần</span>
                <br className="hidden md:block" />
                <span className="mb:text-[48px]  text-[32px]"> 5</span>
              </div>
            </div>
            <div className="md:col-span-5 col-span-6 grid grid-cols-3 gap-4 p-4 md:p-7 bg-white">
              <div className="md:col-span-2 col-span-3">
                <a
                  target="_blank"
                  className="xem-them"
                  href="https://drive.google.com/file/d/1seKISiZGO0VIH_9GqPD9dHym_RzGyGZH/view"
                >
                  <div className="font-black text-[24px]">
                    Văn hóa Doanh nghiệp giúp Người nghỉ hưu Thăng hoa và Tăng
                    giá trị
                  </div>

                  <div className="flex gap-5 mt-6">
                    <img className="w-[60px] h-[60px]" src={Icon7} />
                    <div>
                      <p>
                        <span className="uppercase">Coach</span>
                      </p>
                      <p>
                        <span className="text-[20px] font-bold cl-b">
                          TONY Thái Sơn
                        </span>
                      </p>
                    </div>
                  </div>
                </a>
              </div>
              <div className="hidden md:block">
                <img src={Img18} />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full bg-b text-white pt-[60px] pb-[60px] lg:px-0 px-5">
        <h2 className="title text-white  mb-[20px] md:mb-[60px] ">
          KHOẢN ĐẦU TƯ VÀO CHƯƠNG TRÌNH HUẤN LUYỆN
        </h2>
        <div className="ed-container">
          <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 mb-[20px] md:mb-[40px]">
            <div>
              <img className="m-auto" src={Img25} />
            </div>
            <div>
              <img className="m-auto" src={Img26} />
            </div>
            <div>
              <img className="m-auto" src={Img27} />
            </div>
          </div>

          <div className="text-justify md:text-center md:text-[28px]">
            <span className="italic ">
              Toàn bộ số tiền thu được từ Chương trình sẽ được CBD đưa vào quỹ
              "Khoảnh khắc thay đổi cuộc đời" <br className="hidden xl:block" />
              để xây nhà cho gia đình gặp khó khăn tại Bình Định & Đóng tiền Học
              phí cho trẻ em mồ côi cha/mẹ
              <br className="hidden xl:block" />
              sau đại dịch Covid ở Quận 1.
            </span>
          </div>
          <div className="max-w-[400px]  m-auto px-2 md:px-6 py-6 font-semibold text-sm bg-b text-white my-8 dark:bg-slate-700 dark:text-white rounded-md shadow-sm ring-1 ring-slate-900/5 border-white dark:border-sky-500 border-2 border-dashed">
            <h2 className="text-[24px] text-center leading-7">
              THÔNG TIN VỀ QUỸ <br className=" block md:hidden" /> TỪ THIỆN
            </h2>
            <div className="mt-4 hidden md:block">
              <p className="font-[500] text-[16px]">
                STK: 0366 - Ngân hàng TMCP QUÂN ĐỘI-MB
              </p>
              <p className="font-[500] text-[16px] mt-2">
                CHỦ TÀI KHOẢN: NGUYEN THI BICH HANG
              </p>
            </div>
            <div className="mt-4 block md:hidden">
              <p className="font-[500] text-[14px]">STK: 0366</p>
              <p className="font-[500] text-[14px] mt-2">
                NGÂN HÀNG: TMCP QUÂN ĐỘI-MB
              </p>
              <p className="font-[500] text-[14px] mt-2">
                CHỦ TÀI KHOẢN: NGUYEN THI BICH HANG
              </p>
            </div>
          </div>
          {/* <div className=""></div> */}

          <div className="text-center">
            {/* <hr className="max-w-[350px] w-[100%] m-auto mt-5 mb-5" /> */}
            <h4>
              <span>THỜI GIAN ĐĂNG KÝ ĐÓNG SAU</span>
            </h4>
          </div>

          <div className="flex justify-center mt-5">
            <div className="md:w-[70px] w-[40px] text-center mr-3">
              <div className="md:w-[70px] w-[40px] bg-black flex items-center h-[40px]">
                <p className="text-center w-full">{deadline.days}</p>
              </div>
              <p className="uppercase font-bold pt-1">Ngày</p>
            </div>

            <div className="md:w-[70px] w-[40px] text-center mr-3">
              <div className="md:w-[70px] w-[40px] bg-black flex items-center h-[40px]">
                <p className="text-center w-full">{deadline.hours}</p>
              </div>
              <p className="uppercase font-bold pt-1">giờ</p>
            </div>

            <div className="md:w-[70px] w-[40px] text-center mr-3">
              <div className="md:w-[70px] w-[40px] bg-black flex items-center h-[40px]">
                <p className="text-center w-full">{deadline.minutes}</p>
              </div>
              <p className="uppercase font-bold pt-1">phút</p>
            </div>

            <div className="md:w-[70px] w-[40px] text-center mr-3">
              <div className="md:w-[70px] w-[40px] bg-black flex items-center h-[40px]">
                <p className="text-center w-full">{deadline.seconds}</p>
              </div>
              <p className="uppercase font-bold pt-1">giây</p>
            </div>
          </div>

          <div className="shadow-black text-center mt-10">
            <button
              onClick={handleModal}
              className="uppercase bg-[#D22741] rounded-full py-3 px-10 hover:shadow-2xl"
            >
              Đăng ký ngay hôm nay
            </button>
          </div>
        </div>
      </div>

      <div className="md:my-[100px] my-[20px] ">
        <div className="ed-container">
          <div className="cl-12124">
            <div className="border-l-[8px] border-[#D22741] pl-3 mb-3">
              <h3 className="text-[24px] cl-b">
                Giới thiệu nội dung chương trình từ các Nhà Huấn luyện
              </h3>
            </div>
            <Slider {...settings1}>
              <div>
                <div className="grid grid-cols-2">
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px]  hidden md:block">
                    <div>
                      <img src={Icon14} loading="lazy" />
                    </div>
                    <div className="text-white mt-[30px] md:mt-[50px]">
                      <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                        Từ Giá trị bản thân đến
                        <br className=" hidden md:block" /> Văn hóa Doanh nghiệp
                      </h3>
                      <p className="mt-[20px]">Anna Hằng Nguyễn</p>
                      <p className="mt-[10px]">Firm Owner</p>
                    </div>
                  </div>
                  <div className="md:col-span-1 col-span-2 cl-0281">
                    {/* <img src={Img23} /> */}
                    <iframe
                      width="100%"
                      height="370px"
                      loading="lazy"
                      src={`https://www.youtube.com/embed/uLRudEFbdng`}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Từ GIÁ TRỊ BẢN THÂN đến VĂN HÓA DOANH NGHIỆP"
                    />
                  </div>
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px] block md:hidden h-[280px]">
                    <div className="flex text-white mt-[30px] md:mt-[50px]">
                      <img className="w-[40px] h-[40px] mr-4" src={Icon14} />
                      <div>
                        <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                          Từ Giá trị bản thân đến
                          <br className=" hidden md:block" /> Văn hóa Doanh
                          nghiệp
                        </h3>
                        <p className="mt-[20px]">Anna Hằng Nguyễn</p>
                        <p className="mt-[10px]">Firm Owner</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="grid grid-cols-2">
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px]  hidden md:block">
                    <div>
                      <img src={Icon14} />
                    </div>
                    <div className="text-white mt-[30px] md:mt-[50px]">
                      <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                        Văn hóa Doanh nghiệp quyết định
                        <br className=" hidden md:block" /> Chất lượng Nguồn
                        nhân lực
                      </h3>
                      <p className="mt-[20px]">Kaka Lê Ngọc Đăng</p>
                      <p className="mt-[10px]">Business Coach</p>
                    </div>
                  </div>
                  <div className="md:col-span-1 col-span-2 cl-0281">
                    {/* <img src={Img23} /> */}
                    <iframe
                      src="https://drive.google.com/file/d/1qx8m6a8IsxYlFifgNbS7tBcYpsUDgrMA/preview"
                      width="640"
                      height="480"
                      allow="autoplay"
                    ></iframe>
                  </div>
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px] block md:hidden h-[280px]">
                    <div className="flex text-white mt-[30px] md:mt-[50px]">
                      <img className="w-[40px] h-[40px] mr-4" src={Icon14} />
                      <div>
                        <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                          Văn hóa Doanh nghiệp quyết định
                          <br className=" hidden md:block" /> Chất lượng Nguồn
                          nhân lực
                        </h3>
                        <p className="mt-[20px]">Kaka Lê Ngọc Đăng</p>
                        <p className="mt-[10px]">Business Coach</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="grid grid-cols-2">
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px]  hidden md:block">
                    <div>
                      <img src={Icon14} />
                    </div>
                    <div className="text-white mt-[30px] md:mt-[50px]">
                      <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                        Văn hóa Doanh nghiệp quyết định
                        <br className=" hidden md:block" /> Phương pháp Xây dựng
                        hệ thống...
                      </h3>
                      <p className="mt-[20px]">Tony Thái Sơn</p>
                      <p className="mt-[10px]">Business Coach</p>
                    </div>
                  </div>
                  <div className="md:col-span-1 col-span-2 cl-0281">
                    {/* <img src={Img23} /> */}
                    <iframe
                      src="https://drive.google.com/file/d/1B0UHmEFKFDiAucjtPqexRZsYy7KYKBAf/preview"
                      width="640"
                      height="480"
                      allow="autoplay"
                    ></iframe>
                  </div>
                  <div className="bg-b col-span-2 p-[20px] md:col-span-1 md:p-[50px] block md:hidden  h-[280px]">
                    <div className="flex text-white mt-[30px] md:mt-[50px]">
                      <img className="w-[40px] h-[40px] mr-4" src={Icon14} />
                      <div>
                        <h3 className="text-[24px] md:text-[32px] font-[600] leading-8">
                          Văn hóa Doanh nghiệp quyết định
                          <br className=" hidden md:block" /> Phương pháp Xây
                          dựng hệ thống...
                        </h3>
                        <p className="mt-[20px]">Tony Thái Sơn</p>
                        <p className="mt-[10px]">Business Coach</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </div>
          <div className="grid grid-cols-3 mt-[30px] md:mt-[100px] lg:px-0 px-5">
            <div className="md:col-span-1 md:block hidden">
              <img src={Bg3} />
            </div>
            <div className="md:col-span-2 col-span-3 text-center">
              <h2 className="title cl-b">ĐƠN VỊ TỔ CHỨC</h2>
              <div>
                <p className="bg-[#D12F25] text-white w-[250px] pt-2 pb-2 m-auto">
                  ACTIONCOACH CBD FIRM
                </p>
              </div>
              <div>
                <p className="mt-[20px] md:mt-[40px] text-justify md:text-center">
                  ActionCOACH CBD Firm là văn phòng nhượng quyền của hệ thống
                  ActionCOACH tại TP. HCM. Chúng tôi huấn luyện các chủ doanh
                  nghiệp thông qua các hoạt động đồng hành, hướng dẫn, hỗ trợ,
                  đặt câu hỏi và tạo động lực cho đến khi chủ doanh nghiệp tự
                  tìm ra giải pháp cho thực trạng của doanh nghiệp mình.{" "}
                </p>
                <p className="mt-[20px] text-justify md:text-center">
                  ActionCOACH CBD Firm cam kết mang lại những giá trị cao hơn sự
                  đầu tư của Khách Hàng.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:px-0 px-5">
        <h2 className="title cl-b">VĂN HÓA DOANH NGHIỆP</h2>
        <h4 className="text-center uppercase text-[18px] md:text-[32px] cl-r font-[700]">
          Nền tảng xây dựng một Doanh nghiệp TRƯỜNG TỒN
        </h4>
      </div>

      <div className="bg-white mt-[40px]">
        <img src={Bg4} className="lg:w-full hidden md:block" />
        <img src={Bg6} className="w-full block md:hidden" />
      </div>

      <div className="bg-[#000000] text-white lg:px-0 px-3">
        <div className="ed-container">
          <div className="lg:flex block w-full gap-10 py-[50px] justify-center lg:px-5 px-5">
            <div className="lg:w-[30%] w-full">
              <img src={Logo2} className="md:w-full m-auto" />
              <p className="mt-[20px]">
                Firm Huấn luyện doanh nghiệp số 1 Việt Nam{" "}
              </p>
            </div>
            <div className="lg:w-[30%] w-full">
              <h3 className="f-title">THÔNG TIN LIÊN HỆ</h3>
              <div>
                <p className="mt-[40px]">
                  Địa chỉ: CBD BUILDING,
                  <br />
                  90-92 Lê Thị Riêng, P. Bến Thành, Q.1, TP.HCM
                </p>
                <p className="mt-[20px]">Email: info@actioncoachcbd.com</p>
                <p className="mt-[20px]">Hotline: 1800 8087</p>
              </div>
            </div>
            <div className="lg:w-[30%] w-full hidden md:block">
              <h3 className="f-title">FANPAGE</h3>
              <div className="w-full mt-10">
                <iframe
                  src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2FActionCOACHCBDFirm%2F&tabs&width=280&height=180&small_header=false&adapt_container_width=false&hide_cover=false&show_facepile=false&appId"
                  width="280"
                  height="180"
                  frameBorder="0"
                  allowFullScreen
                  allow="autoplay; clipboard-write; encrypted-media; picture-in-picture;"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {openModal ? (
        <div className="fixed z-50 top-0 h-[100vh] w-full bg-[#afb1b270] flex items-center">
          {Form}

          <p className="text-center text-gray-500 text-xs">
            {/* {full_name} */}
          </p>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}

export default App;
